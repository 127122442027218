import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Dashboard from '../views/dashboard/dashboard.vue'
import Login from '../views/auth/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      auth: true,
      crumbs: '/Dashboard'
    }
  },
  {
    path: '/penjualan',
    component: () => import('../views/transaksi/index.vue'),
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'Penjualan',
        component: () => import('../views/transaksi/penjualan/penjualan.vue'),
        meta: {
          auth: true,
          crumbs: '/Penjualan',
          menu: true
        }
      },
      {
        path: '/penjualan/entry',
        name: 'Entry Penjualan',
        component: () => import('../views/transaksi/penjualan/entry.vue'),
        meta: {
          auth: true,
          crumbs: '/Penjualan/Entry'
        }
      },
      {
        path: '/penjualan/edit/:id',
        name: 'Edit Penjulan',
        component: () => import('../views/transaksi/penjualan/edit.vue'),
        meta: {
          auth: true,
          crumbs: '/Penjualan/Edit'
        }
      },
      {
        path: '/penjualan/retur',
        name: 'Retur',
        component: () => import('../views/transaksi/retur/retur.vue'),
        meta: {
          auth: true,
          crumbs: '/Penjualan/Retur',
          menu: true
        }
      },
      {
        path: '/penjualan/transfer',
        name: 'Transfer',
        component: () => import('../views/transaksi/transfer/transfer.vue'),
        meta: {
          auth: true,
          crumbs: '/Penjualan/Transfer',
          menu: true
        }
      },
      {
        path: '/penjualan/ngoles',
        name: 'Ngoles',
        component: () => import('../views/transaksi/ngoles/ngoles.vue'),
        meta: {
          auth: true,
          crumbs: '/Penjualan/Ngoles',
          menu: true
        }
      },
      {
        path: '/penjualan/reseller',
        name: 'Reseller',
        component: () => import('../views/transaksi/reseller/reseller.vue'),
        meta: {
          auth: true,
          crumbs: '/Penjualan/Reseller',
          menu: true
        }
      },
      {
        path: '/penjualan/mitra',
        name: 'Mitra',
        component: () => import('../views/transaksi/mitra/mitra.vue'),
        meta: {
          auth: true,
          crumbs: '/Penjualan/Mitra',
          menu: true
        }
      }
    ]
  },
  {
    path: '/pengeluaran-admin',
    name: 'Pengeluaran admin',
    component: () => import('../views/pengeluaranadmin/index.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/pengeluaran',
    name: 'Pengeluaran',
    component: () => import('../views/pengeluaran/pengeluaran.vue'),
    meta: {
      auth: true,
      crumbs: '/Pengeluaran'
    }
  },
  {
    path: '/best-seller',
    name: 'Best seller',
    component: () => import('../views/bestsales/index.vue'),
    meta: {
      auth: true,
      crumbs: '/Best Seller'
    }
  },
  {
    path: '/setor',
    name: 'Setor tunai',
    component: () => import('../views/setor/setor.vue'),
    meta: {
      auth: true,
      crumbs: '/Setor tunai'
    }
  },
  {
		path: '/login',
		component: Login,
		name: 'Login',
    meta: {
      crumbs: '/Login'
    }
	},
]

const router = new VueRouter({
  scrollBehavior () {
		return {x: 0, y: 0, behavior: 'smooth'}
	},
	linkActiveClass: 'active',
	linkExactActiveClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	const tokenLocalStorage = localStorage.getItem('token')
	const userId = localStorage.getItem('userId')
	if(to.meta.auth && !store.getters['auth/token'] || to.meta.auth && !tokenLocalStorage || to.meta.auth && !userId) next({name: 'Login'})
	else if(!to.meta.auth && store.getters['auth/token'] && tokenLocalStorage && userId) next({name: 'Dashboard'})
	else next()
})

export default router
