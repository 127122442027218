<template>
	<div class="h-screen top-7">
		<aside class="fixed h-screen bg-gray-800 w-20 z- z-50">
			<div class="h-12 fixed bg-gray-900 left-0 w-20 z-30 flex justify-center items-center border-b border-gray-750">
				<div>
					<span class="text-green-600 text-xl font-semibold">Z</span>
					<span class="text-yellow-400 text-xl font-semibold">H</span>
					<span class="text-red-500 text-xl font-semibold">R</span>
				</div>
			</div>
			<ul v-if="user" class="text-gray-300 pt-12 z-50">
				<li class="text-center text-gray-500 py-5">Main</li>
				<li @mouseover="tip=1" @mouseleave="tip=''" class="relative flex justify-center items-center">
					<div v-show="tip==1" class="absolute animated fadeIn faster bg-gray-900 py-1.5 px-2.5 flex items-center rounded-sm" style="left: 90px">
						<span class="font-medium">BERANDA</span>
						<div class="absolute h-3 w-3 bg-gray-900 transform rotate-45 -left-1.5"></div>
					</div>	
					<router-link class="h-11 flex items-center w-full justify-center hover:bg-gray-750" to="/" exact>
						<span class="h-9 w-9 flex items-center justify-center nav-icon"><i class="icon-home4"></i></span>
					</router-link>
				</li>
				<li @mouseover="tip=2" @mouseleave="tip=''" class="relative flex justify-center items-center">
					<div v-show="tip==2" class="absolute animated fadeIn faster bg-gray-900 py-1.5 px-2.5 flex items-center rounded-sm" style="left: 90px">
						<span class="font-medium">TRANSAKSI</span>
						<div class="absolute h-3 w-3 bg-gray-900 transform rotate-45 -left-1.5"></div>
					</div>	
					<router-link class="h-11 flex items-center w-full justify-center hover:bg-gray-750" to="/penjualan">
						<span class="h-9 w-9 flex items-center justify-center nav-icon"><i class="icon-barcode2"></i></span>
					</router-link>
				</li>
				<li v-if="user._id == '64780c26c23297e3f8058ef8'" @mouseover="tip=3" @mouseleave="tip=''" class="relative flex justify-center items-center">
					<div v-show="tip==3" class="absolute animated fadeIn faster bg-gray-900 py-1.5 px-2.5 flex items-center rounded-sm" style="left: 90px">
						<span class="font-medium whitespace-nowrap">PENGELUARAN ADMIN</span>
						<div class="absolute h-3 w-3 bg-gray-900 transform rotate-45 -left-1.5"></div>
					</div>	
					<router-link class="h-11 flex items-center w-full justify-center hover:bg-gray-750" to="/pengeluaran-admin">
						<span class="h-9 w-9 flex items-center justify-center nav-icon"><i class="icon-file-download"></i></span>
					</router-link>
				</li>
				<li @mouseover="tip=4" @mouseleave="tip=''" class="relative flex justify-center items-center">
					<div v-show="tip==4" class="absolute animated fadeIn faster bg-gray-900 py-1.5 px-2.5 flex items-center rounded-sm" style="left: 90px">
						<span class="font-medium">PENGELUARAN</span>
						<div class="absolute h-3 w-3 bg-gray-900 transform rotate-45 -left-1.5"></div>
					</div>	
					<router-link class="h-11 flex items-center w-full justify-center hover:bg-gray-750" to="/pengeluaran">
						<span class="h-9 w-9 flex items-center justify-center nav-icon"><i class="icon-basket"></i></span>
					</router-link>
				</li>
				<li @mouseover="tip=5" @mouseleave="tip=''" class="relative flex justify-center items-center">
					<div v-show="tip==5" class="absolute animated fadeIn faster bg-gray-900 py-1.5 px-2.5 flex items-center rounded-sm" style="left: 90px">
						<span class="font-medium">BEST</span>
						<div class="absolute h-3 w-3 bg-gray-900 transform rotate-45 -left-1.5"></div>
					</div>	
					<router-link class="h-11 flex items-center w-full justify-center hover:bg-gray-750" to="/best-seller">
						<span class="h-9 w-9 flex items-center justify-center nav-icon"><i class="icon-medal-star"></i></span>
					</router-link>
				</li>
				<li v-if="user._id == '64780c26c23297e3f8058ef8'" @mouseover="tip=6" @mouseleave="tip=''" class="relative flex justify-center items-center">
					<div v-show="tip==6" class="absolute animated fadeIn faster bg-gray-900 py-1.5 px-2.5 flex items-center rounded-sm" style="left: 90px">
						<span class="font-medium">SETOR</span>
						<div class="absolute h-3 w-3 bg-gray-900 transform rotate-45 -left-1.5"></div>
					</div>	
					<router-link class="h-11 flex items-center w-full justify-center hover:bg-gray-750" to="/setor">
						<span class="h-9 w-9 flex items-center justify-center nav-icon"><i class="icon-library2"></i></span>
					</router-link>
				</li>
			</ul>
		</aside>
		<div class="pl-20">
			<nav class="h-12 bg-white border-b right-0 px-5 left-20 fixed flex items-center justify-between z-20">
				<div>
					<span v-if="shop" class="text-lg font-semibold">{{shop.name}}</span>
				</div>
				<div>
					<ul>
						<li class="relative">
							<dropdown-menu :right="true">
								<a href="#" class="relative px-4 flex items-center h-12 user hover:bg-gray-200 hover:bg-opacity-50">
									<div class="h-8 w-8 rounded-full bg-red-500 flex items-center justify-center text-red-50">
										<div v-if="user">{{user.name[0]}}</div>
									</div>
									<div class="ml-2"><span v-if="user">{{user.name}}</span></div>
									<i class="icon-arrow-down22 ml-2"></i>
								</a>
								<template #dropdown>
									<div class=" w-48 ">
										<router-link to="/my-account" class="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"><i class="icon-user mr-4"></i>Akun Saya</router-link>
										<a @click="signOut" href="#" class="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"><i class="icon-switch2 mr-4"></i>Keluar</a>
									</div>
								</template>
							</dropdown-menu>
						</li>
					</ul>
				</div>
			</nav>
			<div class="pt-12 pb-5 relative">
				<breadcrumb></breadcrumb>
				<transition name="fade" mode="out-in">
					<router-view />
				</transition>
			</div>
			
		</div>

		<modal :show="modalBarang">
			<div class=" w-3/4 bg-white rounded overflow-hidden relative">
				<div class="flex items-center justify-between px-5 py-3 border-b">
					<div>CEK STOK BARANG</div>
					<div>
						<button @click="modalBarang=false"><i class="icon-cross2"></i></button>
					</div>
				</div>
				<div class="p-5">
					<input ref="search" v-model="search" type="text" name="search" class="form-control" placeholder="CARI SKU ATAU NAMA BARANG'">
				</div>
				<div style="height: 500px">
					<table class="w-full">
						<thead>
							<th class="pl-5 py-3 pr-3 bg-red-500 text-red-50" style="width: 10%">SKU</th>
							<th class="p-3 bg-red-500 text-red-50" style="width: 60%">NAMA BARANG</th>
							<th class="p-3 bg-red-500 text-red-50" style="width: 20%">TOKO</th>
							<th class="pl-3 py-3 pr-5 bg-red-500 text-red-50 text-right" style="width: 10%">STOK</th>
						</thead>
						<tbody v-if="isLoading" class="divide-y">
							<tr v-for="i in 10" :key="i">
								<td class="pl-5 py-3 pr-3 ph-item">
									<div class="h-4 w-16 bg-gray-200"></div>
								</td>
								<td class="p-3 ph-item">
									<div class="h-4 w-72 bg-gray-200"></div>
								</td>
								<td class="p-3 ph-item">
									<div class="h-4 w-32 bg-gray-200"></div>
								</td>
								<td class="p-3 ph-item">
									<div class="h-4 w-32 bg-gray-200"></div>
								</td>
							</tr>
						</tbody>
						<tbody v-else class="divide-y">
							<tr v-for="(item, i) in items" :key="i">
								<td class="pl-5 py-3 pr-3">{{item.sku}}</td>
								<td class="p-3">{{item.name}}</td>
								<td class="p-3">{{item.shop}}</td>
								<td class="pl-3 py-3 pr-5 text-right">{{item.qty}}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="text-right py-3 pr-5">
					<button @click="modalBarang=false" class="h-9 px-5 text-blue-500 hover:text-blue-600 border rounded-sm">TUTUP</button>
				</div>
			</div>
		</modal>
		<div class="fixed right-3 bottom-0">
			<button @click="modalBarang=true" class="h-9 bg-red-500 px-5 rounded-t-lg text-red-50 hover:bg-red-600">CEK STOK BARANG</button>
		</div>
		<alert ref="alert"></alert>
	</div>
</template>

<script>
import DropdownMenu from './components/DropdownMenu.vue'
import Breadcrumb from './components/Breadcrumb.vue'
import { mapActions, mapGetters } from 'vuex'
import Modal from './components/Modal.vue'
import axios from 'axios'
import debounce from 'lodash.debounce'
import alert from './components/alert.vue'
export default {
	components: {DropdownMenu, Breadcrumb, Modal, alert},
	data () {
		return {
			isLoading: false,
			tip: false,
			search: '',
			serachData: '',
			modalBarang: false,
			items: []
		}
	},
	created () {
		this.serachData = debounce(() => {
			this.getBarang()
		}, 500)
	},
	computed: {
		...mapGetters({
			shop: 'auth/shop',
			user: 'auth/user'
		})
	},
	methods: {
		getBarang () {
			this.isLoading = true
			axios.get('/inventory/stock/barang', {
				params: {
					search: this.search
				}
			})
			.then(res => {
				this.isLoading = false
				this.items = res.data
			})
		},
		...mapActions({
			logout: 'auth/logout'
		}),
		async signOut () {
			this.isLoading = true
			const token = localStorage.getItem('refreshToken')
			await this.logout(token)
			.then(() => {
				this.$router.push('/login')
				this.isLoading = false
			})
		}
	},
	watch: {
		user (val) {
			if(val) {
				if(val.role !== 'kasir') {
					this.signOut()
					this.$refs.alert.open('error', 'Monon maaf anda tidak memiliki akses')
				} 
			}
		},
		search () {
			this.serachData()
		},
		modalBarang (val) {
			if(val) {
				this.$nextTick(() => {
					this.$refs.search.focus()
				})
			}
		}
	}
	
}
</script>

<style>
.active .nav-icon {
    @apply text-green-500 border border-green-500 rounded-sm bg-green-500 bg-opacity-20;
}
.err .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
	border-color: #ef4444 !important;
}
</style>