<template>
    <div class="m-5">
        <div class="grid grid-cols-3 gap-5">
            <div class="col-span-2 bg-cyan-500 border border-cyan-500 rounded-sm">
                <div class="p-5 mb-20">
                    <div class="flex items-center justify-end text-cyan-50">
                        <div>
                            <i class="icon-coin-dollar icon-5x"></i>
                        </div>
                        <div class="ml-10 text-right">
                            <div class="text-4xl font-semibold">{{Intl.NumberFormat().format(totalMingguIni)}}</div>
                            <div>PENDAPATAN 7 HARI TERAKHIR</div>
                        </div>
                    </div>
                </div>
                <div class="h-52 px-5">
                    <canvas id="saleChart"></canvas>
                </div>
            </div>
            <div class="clo-span-1 space-y-5">
                <div class="bg-green-500 py-5 rounded-sm grid grid-cols-3">
                    <div class="border-r border-green-400 px-5">
                        <div class="text-xl font-semibold text-right text-green-50">{{Intl.NumberFormat().format(pendapatanHariIni)}}</div>
                        <div class="text-right text-green-200">PENJUALAN</div>
                    </div>
                    <div class="border-r border-green-400 px-5">
                        <div class="text-xl font-semibold text-right text-green-50">{{Intl.NumberFormat().format(ngoles)}}</div>
                        <div class="text-right text-green-200">NGOLES</div>
                    </div>
                    <div class="px-5">
                        <div class="text-xl font-semibold text-right text-green-50">{{Intl.NumberFormat().format(reseller)}}</div>
                        <div class="text-right text-green-200">RESELLER</div>
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-5">
                    <div class="flex items-center p-5 bg-white rounded border">
                        <div>
                            <i class="icon-coin-dollar icon-3x text-gray-400"></i>
                        </div>
                        <div class="w-full">
                            <div class="text-lg text-right font-semibold">{{Intl.NumberFormat().format(tunai)}}</div>
                            <div class="text-right text-gray-400">TUNAI</div>
                        </div>
                    </div>
                    <div class="flex items-center p-5 bg-white rounded border">
                        <div>
                            <i class="icon-credit-card icon-3x text-gray-400"></i>
                        </div>
                        <div class="w-full">
                            <div class="text-lg text-right font-semibold">{{Intl.NumberFormat().format(debit)}}</div>
                            <div class="text-right text-gray-400">DEBIT / KREDIT</div>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-5">
                    <div class="flex items-center p-5 bg-white rounded border">
                        <div>
                            <i class="icon-link2 icon-3x text-gray-400"></i>
                        </div>
                        <div class="w-full">
                            <div class="text-lg text-right font-semibold">{{Intl.NumberFormat().format(transfer)}}</div>
                            <div class="text-right text-gray-400">TRANSFER</div>
                        </div>
                    </div>
                    <div class="flex items-center p-5 bg-white rounded border">
                        <div>
                            <i class="icon-calculator3 icon-3x text-gray-400"></i>
                        </div>
                        <div class="w-full">
                            <div class="text-lg text-right font-semibold">{{Intl.NumberFormat().format(multi)}}</div>
                            <div class="text-right text-gray-400">MULTI BAYAR</div>
                        </div>
                    </div>
                </div>
                <div class="bg-red-500 p-5 rounded-sm">
                    <div class="text-xl font-semibold text-right text-red-50">{{Intl.NumberFormat().format(pengeluaranHariIni)}}</div>
                    <div class="text-right text-red-200">PENGELUARAN HARI INI</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Chart from 'chart.js/auto'
import axios from 'axios'
export default {
    data () {
        return {
            pendapatanHariIni: 0,
            pengeluaranHariIni: 0,
            tunai: 0,
            debit: 0,
            transfer: 0,
            multi: 0,
            total: [],
            labels: [],
            counts: [],
            totalMingguIni: 0,
            ngoles: 0,
            reseller: 0
        }
    },
    mounted () {
        let app = this
        this.getData()
        var ctxSale = document.getElementById('saleChart')
        // const verticalLine = {
        //     id: 'verticalLine',
        //     afterDatasetDraw: (chart, params) => {
        //         const {ctx, chartArea: {bottom}, scales: {x, y}} = chart
        //         ctx.save()

        //         // console.log(params.meta.data)

        //         ctx.strokeStyle = 'white'
        //         ctx.lineWidth = 1;
        //         function drawLine (xPos, yPos) {
        //             ctx.setLineDash([5, 4])
        //             ctx.beginPath()
        //             ctx.moveTo(x.getPixelForValue(xPos), y.getPixelForValue(yPos))
        //             ctx.lineTo(x.getPixelForValue(xPos), bottom)
        //             ctx.stroke()
        //         }
        //         params.meta.data.forEach(point => {
        //             const parsed = point
        //             console.log(parsed.x, drawLine)
        //             drawLine(parsed.x, parsed.y)
        //         });
        //         ctx.restore();
        //     }
        // }
        window.slsChart = new Chart(ctxSale, {
            type: 'line',
            data: {
                labels: app.labels,
                datasets: [{
                    label: '',
                    data: app.total,
                    borderColor: 'white',
                    fill: false,
                    backgroundColor: 'white',
                    pointStyle: 'circle',
                    pointRadius: 7,
                }]
            },
            // plugins: [verticalLine],
            options: {
                maintainAspectRatio: false,
                plugins: {
					legend: {
						display: false,
					},
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            label: (contex) => {
                                return ` Rp. ${contex.formattedValue}`
                            },
                            afterTitle: (contex) => {
                                return ` Transaksi: ${app.counts[contex[0].dataIndex]}`

                            }
                        }
                    }
				},
                scales: {
                    x: {
						labels: false,
						ticks: {
							color: 'rgb(239,68,68)',
							display: false
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: false,
                            drawBorder: false
						}
					},
                    y: {
						labels: false,
                        min: 0,
						ticks: {
							color: 'rgb(239,68,68)',
							display: false
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: false,
                            drawBorder: false
						}
					},
                }
            }
        })
    },
    methods: {
        getData () {
            axios.get('/pos')
            .then(res => {
                if(res.data.ngoles) {
                    this.ngoles = res.data.ngoles.total
                }
                if(res.data.reseller) {
                    this.reseller = res.data.reseller.total
                }
                if(res.data.omzet) {
                    this.pendapatanHariIni = res.data.omzet.total
                    this.tunai = res.data.omzet.tunai
                    this.debit = res.data.omzet.debit
                    this.transfer = res.data.omzet.transfer
                    this.multi = res.data.omzet.multi
                }
                const stats = res.data.stats
                for(let i = 0; i < stats.length; i++) {
                    const st = stats[i]
                    this.labels.push(st._id)
                    this.total.push(st.total)
                    this.counts.push(st.count)
                    this.totalMingguIni += st.total
                }
                if(res.data.pengeluaran) {
                    this.pengeluaranHariIni = res.data.pengeluaran.total
                }
                window.slsChart.update()
            })
        }
    },

}
</script>